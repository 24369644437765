import React, { useState} from 'react';
import Hero_img from '../images/main_banner_2.JPG';
import Hero_img_avif from '../images/main_banner_2.avif';
import Hero_img_webp from '../images/main_banner_2.webp';
import Hero_img_mobile_2 from '../images/mobile_view_2.jpg';
import Hero_img_mobile_2_avif from '../images/mobile_view_2.avif';
import Hero_img_mobile_2_webp from '../images/mobile_view_2.webp';
import health_issue from '../images/health_issue.JPG';
import health_issue_avif from '../images/health_issue.avif';
import health_issue_webp from '../images/health_issue.webp';
import feedback01 from '../images/Feedback-01.png';
import feedback01_avif from '../images/Feedback-01.avif';
import feedback01_webp from '../images/Feedback-01.webp';
import feedback02 from '../images/Feedback-02.png';
import feedback02_avif from '../images/Feedback-02.avif';
import feedback02_webp from '../images/Feedback-02.webp';
import feedback03 from '../images/Feedback-03.png';
import feedback03_avif from '../images/Feedback-03.avif';
import feedback03_webp from '../images/Feedback-03.webp';
import Spinner from './Spinner';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useMediaQuery } from 'react-responsive';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import avatar_wssap from '../images/terraepawz_logo.png';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Wgp = React.lazy(() => import('../components/Carousel_wgp'));
const Fs = React.lazy(() => import('../components/Carousel_fs'));
const Ns = React.lazy(() => import('../components/Carousel_nc'));
const Gp = React.lazy(() => import('../components/Carousel_guardpro'));
const Ip = React.lazy(() => import('../components/Carousel_immunopro'));


const Hero = () => {

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const phoneNumbers = ['+60105495008', '+60166643971', '+60108483848'];

  const getRandomPhoneNumber = () => {
    const randomIndex = Math.floor(Math.random() * phoneNumbers.length);
    return phoneNumbers[randomIndex];
  };

  const [phoneNumber, setPhoneNumber] = useState(getRandomPhoneNumber());

  const handleClick = () => {

    setPhoneNumber(getRandomPhoneNumber());

  };

  //const [loading, setLoading] = useState(true);

  //useEffect(() => {
    // Simulate a data fetch
   // setTimeout(() => {
     // setLoading(false);
    //}, 3000);
 // }, []);






  return (
    <div class="">

      {/* ---------- Fully Stretch Banner ---------- */}


      <div class="relative">
        {isMobile ? (



          <picture>
            <source srcSet={Hero_img_mobile_2_avif} type="image/avif" />
            <source srcSet={Hero_img_mobile_2_webp} type="image/webp" />
            <img src={Hero_img_mobile_2} alt="Hero" />
          </picture>


        ) : (

          <picture>
            <source srcSet={Hero_img_avif} type="image/avif" />
            <source srcSet={Hero_img_webp} type="image/webp" />
            <img src={Hero_img} alt="Hero" className="w-full h-auto" />
          </picture>

        )}

      </div>
      {/* ---------- End Fully Stretch Banner ---------- */}
      <div class="py-6 sm:py-6">
        <div class="mx-auto max-w-7xl px-2 lg:px-2">

          <div class="mb-8 flex justify-center">
            <p
              class="relative rounded-full px-4 py-1.5 text-sm leading-6 text-gray-600 ring-1 ring-inset ring-gray-900/10 hover:ring-gray-900/20">
              <span class="hidden md:inline">Catch our new product.</span>
              <span target="_blank" class="font-semibold text-lime-600">
                <span class="absolute inset-0" ></span> Read
                the update <span>→</span>
              </span>
            </p>
          </div>
          <div class="mx-auto max-w-2xl text-center">
            <h1 class="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
              Terraepawz
            </h1>
            <h1 class="text-4xl font-bold tracking-tight text-blue-700 sm:text-6xl">
              Ultimate Pets Health Supplement
            </h1>
            <p class="mt-6 text-lg leading-8 text-gray-600">
              Introducing our new platform which you can read all the details about Terraepawz Product.
              Plus you can buy directly from this platform.
            </p>
            <div class="mt-10 flex items-center justify-center gap-x-6">
              <span
                class="rounded-md bg-lime-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-lime-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-600">Check
                out the demo
              </span>
              <span class="text-sm font-semibold leading-6 text-gray-900">
                Learn more
                <span>→</span>
              </span>
            </div>
          </div>



          {/* ---------- Pets Health Issue Section ---------- */}
          <h1 class="text-2xl mb-4 text-center tracking-tight mt-4 text-[#56BFBB] sm:text-6xl md:text-6xl lg:text-6xl">
            Pet's Health<span class="text-[#E35025]"> Common Issue</span>
          </h1>
          <div class="flex flex-wrap max-w-screen-sm mx-auto gap-4 sm:flex-row items-center justify-center">
            <div class="shadow-lg rounded">

              <picture>
                <source srcSet={health_issue_avif} type="image/avif" />
                <source srcSet={health_issue_webp} type="image/webp" />
                <img src={health_issue} alt="" />
              </picture>


            </div>
          </div>

          {/* ---------- End Pets Health Issue Section ---------- */}

          {/* ---------- Terrae Product Section ---------- */}

          <div class="border-b mb-5 mt-8 flex justify-between text-2xl font-roboto">
            <div class="text-gray-600 flex items-center pb-2 pr-2 border-b-2 border-indigo-600">

              <span class="font-semibold inline-block">Terraepawz Top 5 Product</span>
            </div>
           
          </div>


          <div className="flex flex-wrap gap-4 sm:flex-row items-center justify-center">

    
           <div class="w-full md:max-w-xs">
              <div class="shadow-lg rounded overflow-hidden ">
                <React.Suspense fallback={<div class="text-center text-gray-600"><Spinner/><br />Loading Product NasalCare</div>}>
                  <Wgp />
                </React.Suspense>
              </div>
            </div>
   
            <div class="w-full md:max-w-xs">
              <div class="shadow-lg rounded overflow-hidden ">
                <React.Suspense fallback={<div class="text-center text-gray-600"><Spinner/><br />Loading Product NasalCare</div>}>
                  <Ns />
                </React.Suspense>
              </div>
            </div>

            <div class="w-full md:max-w-xs">
              <div class="shadow-lg rounded overflow-hidden ">
                <React.Suspense fallback={<div class="text-center text-gray-600"><Spinner/><br />Loading Product Fungus Shield</div>}>
                  <Fs />
                </React.Suspense>
              </div>
            </div>

            <div class="w-full md:max-w-xs">
              <div class="shadow-lg rounded overflow-hidden ">
                <React.Suspense fallback={<div class="text-center text-gray-600"><Spinner/><br />Loading Product GuardPro</div>}>
                  <Gp />
                </React.Suspense>
              </div>
            </div>

            <div class="w-full md:max-w-xs">
              <div class="shadow-lg rounded overflow-hidden ">
                <React.Suspense fallback={<div class="text-center text-gray-600"><Spinner/><br />Loading Product ImmunoPro</div>}>
                  <Ip />
                </React.Suspense>
              </div>
            </div>


          </div>

          {/* ---------- End Terrae Product Section ---------- */}





          <div class="border-b mb-5 mt-8 flex justify-between text-2xl font-roboto">
            <div class="text-gray-600 flex items-center pb-2 pr-2 border-b-2 border-indigo-600">

              <span class="font-semibold inline-block">Customer's Testimonial</span>
            </div>
        
          </div>


          <div className="flex flex-col gap-4 mx-4 sm:flex-row items-center justify-center">

            <div class="max-w-sm">
              <div class="shadow-lg rounded overflow-hidden">

                <picture>
                  <source srcSet={feedback01_avif} type="image/avif" />
                  <source srcSet={feedback01_webp} type="image/webp" />
                  <img src={feedback01} alt="" />
                </picture>


              </div>
            </div>

            <div class="max-w-sm">
              <div class="shadow-lg rounded overflow-hidden">

                <picture>
                  <source srcSet={feedback02_avif} type="image/avif" />
                  <source srcSet={feedback02_webp} type="image/webp" />
                  <img src={feedback02} alt=""/>
                </picture>


              </div>
            </div>

            <div class="max-w-sm">
              <div class="shadow-lg rounded overflow-hidden">

                <picture>
                  <source srcSet={feedback03_avif} type="image/avif" />
                  <source srcSet={feedback03_webp} type="image/webp" />
                  <img src={feedback03} alt="" />
                </picture>


              </div>
            </div>

          </div>



          <div class="flex flex-col mt-8 sm:flex-row items-center justify-center">

            <div onClick={handleClick}>
              <FloatingWhatsApp phoneNumber={phoneNumber} accountName="TerraMed Team" avatar={avatar_wssap} chatMessage="Hi..berminat dengan produk Terraepawz? Sales team kami akan membantu anda" />
            </div>


          </div>

          {/* Last div before closing*/}




        </div>
      </div>
    </div>

  );
};

export default Hero;