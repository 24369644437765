// Checkout.js
import React, { useState } from 'react';


const CustomerService = () => {

    return (

        <div class="py-3 sm:py-3">
            <div class="mx-auto max-w-7xl px-6 lg:px-8 ">

                <div class="border-b mb-5 mt-2 flex justify-between text-lg font-roboto">
                    <div class="text-gray-600 flex items-center pb-2 pr-2 border-b-2 border-indigo-600 uppercase">

                        <a href="#" class="font-semibold inline-block">Customer Service</a>
                    </div>
                    <a href="#"></a>
                </div>


                <ul class="max-w-4xl mx-auto mt-20 my-8 divide-y shadow shadow-blue-600 rounded-xl">
                    <li>
                        <details class="group">
                            <summary class="flex items-center gap-3 px-4 py-3 font-medium marker:content-none hover:cursor-pointer">
                                <svg class="w-5 h-5 text-gray-500 transition group-open:rotate-90" xmlns="http://www.w3.org/2000/svg"
                                    width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd"
                                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z">
                                    </path>
                                </svg>
                                <span>FAQ</span>
                            </summary>

                            <article class="px-4 pb-4">
                                <p>
                                    We are working on it..
                                </p>
                            </article>
                        </details>
                    </li>
                    <li>
                        <details class="group">
                            <summary class="flex items-center gap-3 px-4 py-3 font-medium marker:content-none hover:cursor-pointer">
                                <svg class="w-5 h-5 text-gray-500 transition group-open:rotate-90" xmlns="http://www.w3.org/2000/svg"
                                    width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd"
                                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z">
                                    </path>
                                </svg>
                                <span>Privacy & Policy</span>
                            </summary>

                            <article class="px-4 pb-4">
                                <p>
                                    Welcome to TerraèPawz online store, operated by Vetrilabz Sdn. Bhd. (1538013-P). Throughout the site, the terms “we”, “us” and “our” refer to Vetrilabz Sdn. Bhd
                                    <br /><br />
                                    Terms and conditions stated below apply to all visitors and users of https://terraepawz.com. You are bound by these terms and conditions as long as you're on https://terraepawz.com
                                    <br /><br />
                                    *GENERAL*
                                    The content of terms and conditions may be changed, moved or deleted at any time. Please note that we have the right to change the contents of the terms and conditions without any notice. Any violation of rules and regulations of these terms and conditions, we will take immediate actions against the offender(s).
                                    <br /><br />
                                    *SITE CONTENTS AND COPYRIGHTS*
                                    Unless otherwise noted, all materials, including images, illustrations, designs, icons, photographs, video clips, and written and other materials that appear as part of our website, in other words “Contents of the Site” are copyrights, trademarks, trade dress and/or other intellectual properties owned, controlled or licensed by Vetrilabz Sdn. Bhd..
                                    <br /><br />
                                    *PRIVACY*
                                    When you make a purchase on our website, as part of the buying and selling process, we collect the personal information you give us, such as your name, address, and email address. As you are browse through our website, we will also automatically receive your computer’s internet protocol (IP) address in order to provide us with information that helps us learn about your browser and operating system.
                                    <br /><br />
                                    *COMMENTS AND FEEDBACKS*
                                    All comments and feedbacks to us may be submitted to by email customer.support@terraepawz.com. User shall agree that there will be no comment(s) submitted to us that will violate any rights of any third party, including copyrights, trademarks, privacy of other personal or proprietary right(s). Furthermore, the user shall agree there will not be content of unlawful, abusive, or obscene material(s) submitted to the site. User will be the only one responsible for any comment's content made.
                                    <br /><br />
                                    *PRODUCT INFORMATION*
                                    We have made every effort to display the colors and images of our products as accurately as possible. We cannot guarantee all actual products will be exactly the same shown on the monitor as that is depending on the user monitor. All descriptions of products, includes pricing are subject to change at anytime without notice, at the sole discretion of us. We reserve the right to limit the quantities of any products or services that we offer. We do not warrant that the quality of any products, services, information or other material purchased or obtained by the users/customers will meet their expectations, or that any errors in the service will be corrected.
                                    <br /><br />
                                    *NEWSLETTERS/BROADCASTS*
                                    User shall agree that we may send newsletter/broadcast regarding the latest news/products/promotions etc through email/WhatsApp to the user.
                                    <br /><br />
                                    *INDEMNIFICATION*
                                    User agree to indemnify, defend and hold harmless Vetrilabz Sdn. Bhd. and our subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns and employees, harmless from any claim or demand, including reasonable attorneys’ fees, made by any third-party due to our arising out of your breach of these Terms and Conditions or the documents they incorporate by reference, or your violation of any laws or rights of a third-party.
                                    <br /><br />
                                    *LINK TO OTHER SITES*
                                    Any access link to third party sites is at your own. We will not be related or involved to any such website if the user's content/product(s) got damaged or loss have any connection with third party site.
                                    <br /><br />
                                    *TERMINATION*
                                    The obligations and liabilities of the parties incurred prior to the termination date shall survive the termination of this agreement for all purposes. These Terms and Conditions are effective unless and until terminated by either user or us. You may terminate these Terms and Conditions at any time by notifying us that you no longer wish to use our services, or when you cease using our site. If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or provision of these Terms and Conditions, we also may terminate this agreement at any time without notice and you will remain liable for all amounts due up to and including the date of termination; and/or accordingly may deny you access to our services (or any part thereof).
                                    <br /><br />
                                    *PAYMENTS*
                                    All Goods purchased are subject to a one-time payment. Payment can be made through various payment methods we have available, such as Visa, MasterCard or online payment methods. Payments cards (credit cards or debit cards) are subject to validation checks and authorization by your card issuer. If we do not receive the required authorization, we will not be liable for any delay or non-delivery of your order.





                                </p>
                            </article>
                        </details>
                    </li>
                    <li>
                        <details class="group">
                            <summary class="flex items-center gap-3 px-4 py-3 font-medium marker:content-none hover:cursor-pointer">
                                <svg class="w-5 h-5 text-gray-500 transition group-open:rotate-90" xmlns="http://www.w3.org/2000/svg"
                                    width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd"
                                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z">
                                    </path>
                                </svg>
                                <span>Return & Refund</span>
                            </summary>

                            <article class="px-4 pb-4">
                                <p>
                                    *RETURNS, EXCHANGES AND REFUNDS*
                                    <br /><br />
                                    The return, exchange and refund period is limited to 5 days after your order is shipped. With clear pictures and videos upon receiving. These terms and conditions are applied:
                                    <br />
                                    A) Only new, unworn, unwashed products. <br />
                                    B) Sale, promotional and markdown items are non-returnable, non-exchangeable and non-refundable.<br />
                                    C) Products can only be exchanged once, with the exact same product only.<br />
                                    D) Please note that we do not provide pick-up services for returns and exchanges. Shipping cost is non-claimable.<br />
                                    E) We reserve the right not to allow the return, exchange or refund of products from any transactions, even if the conditions above are met.<br />
                                    <br /><br />
                                    Please contact us at +010-848 3 848 or admin@terraepawz.com for any inquiries.
                                </p>
                            </article>
                        </details>
                    </li>
                    <li>
                        <details class="group">
                            <summary class="flex items-center gap-3 px-4 py-3 font-medium marker:content-none hover:cursor-pointer">
                                <svg class="w-5 h-5 text-gray-500 transition group-open:rotate-90" xmlns="http://www.w3.org/2000/svg"
                                    width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd"
                                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z">
                                    </path>
                                </svg>
                                <span>Shipping Delivery</span>
                            </summary>

                            <article class="px-4 pb-4">
                                <p>

                                    *SHIPPING AND DELIVERY POLICY*
                                    <br /><br />
                                    We will process and ship your order within 2-5 working days after the payment is received. We are not responsible for the condition and status of your order after we shipped out to the courier company as a third-party. You may reach out the courier company accordingly.
                                    <br />
                                    Please note that, while we will try our best to have your orders delivered to you as soon as possible, kindly allow for slight delivery delays during peak periods (Christmas, Hari Raya Aidilfitri, Chinese New Year, etc.).
                                    <br /><br />
                                    Working days: Monday to Friday (except public holiday).

                                </p>
                            </article>
                        </details>
                    </li>

                </ul>
            </div>
        </div>
    );
}

export default CustomerService;
