// useSessionId.js
import { useState, useEffect } from 'react';
import { getSessionId } from './sessionManager';

const useSessionId = () => {
  const [sessionId, setSessionId] = useState('');

  useEffect(() => {
    const id = getSessionId();
    setSessionId(id);
  }, []);

  return sessionId;
};

export default useSessionId;
