// Checkout.js
import React from 'react';


const VMission = () => {



   return (


      <div class="py-3 px-3 sm:py-3 ">
         <div class="mx-auto max-w-7xl  lg:px-8 ">

            <div class="border-b mb-5 mt-2 flex justify-between text-lg font-roboto px-2">
               <div class="text-gray-600 flex items-center pb-2 pr-2 border-b-2 border-indigo-600 uppercase">

                  <span class="font-semibold inline-block">Our Vission and Mission</span>
               </div>
          
            </div>
            <b>Vision</b><br /><br />
Terraepawz envisions a world where every pet thrives in optimal health and happiness. We are committed to crafting innovative, science-backed supplement products that enhance the well-being of beloved pets, fostering strong bonds between animals and their owners. Our vision is to set new standards in pet nutrition, ensuring a brighter and healthier future for furry companions worldwide.

<br /><br />


<b>Mission</b><br /><br />

Terraepawz: Pioneering Pet Wellness in Asia<br /><br />

In the heart of Asia, Terraepawz emerges with a mission – to become a leading provider of pet supplements, redefining the standards of pet wellness. Committed to enhancing the lives of furry companions, our vision is rooted in three key pillars:
<br />
1. Quality Assurance:<br />
   - Terraepawz is dedicated to sourcing and producing the highest quality pet supplements. Each product undergoes rigorous testing to meet international standards, ensuring safety and efficacy for pets of all breeds.
   <br /><br />
2. Holistic Health Approach:<br />
   - We believe in a holistic approach to pet wellness. Terraepawz supplements are crafted with a blend of natural ingredients, tailored to address specific health needs. From joint support to skin and coat enhancement, our products cater to the diverse well-being requirements of pets.
   <br /><br />
3. Education and Advocacy:<br />
   - Terraepawz is not just a brand; it's a resource hub for pet owners. We aim to empower pet parents with knowledge about optimal nutrition, preventive care, and the importance of supplements in a pet's diet. Through webinars, articles, and community engagement, we strive to create a well-informed and caring pet community.
   <br /><br />
As we embark on this journey, Terraepawz envisions becoming the go-to choice for pet owners seeking premium, reliable supplements in Asia. We are dedicated to fostering a healthier, happier life for pets, one supplement at a time. Join us on this mission towards a future where every pet thrives, and the bond between humans and their four-legged companions grows stronger.




         </div>
      </div>
   );
}

export default VMission;
