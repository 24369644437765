import React from 'react';
import CustomerService from '../components/CustomerService';

const Customerservice = () => {
  return (

<CustomerService/>

  );
};

export default Customerservice;