import React from 'react';
import ComponentCheckout from '../components/Checkout';

const Checkout = () => {
  return (

<ComponentCheckout/>

  );
};

export default Checkout;