import React from 'react';
import VMission from '../components/VMission';

const Pages_vmission = () => {
  return (

<VMission/>

  );
};

export default Pages_vmission;