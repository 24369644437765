import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Cart from "./pages/Cart";
import Home from "./pages/Home";
import Blogs from "./pages/Blogs";
import Vmission from "./pages/VMission";
import Checkout from "./pages/Checkout";
import TPayment from "./pages/TPayment";
import Customerservice from "./pages/CustomerService";
import Product from "./pages/Product";
import NavbarY from "./components/Navbar_1";
import NavbarX from "./components/Navbar_2";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <div className="container">
      <Router>
        <ScrollToTop />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <NavbarX />
                <Home />
              </>
            }
          />
          <Route
            path="/Cart"
            element={
              <>
                <NavbarY />
                <Cart />
              </>
            }
          />
          <Route
            path="/Product"
            element={
              <>
                <NavbarY />
                <Product />
              </>
            }
          />
             
          <Route
            path="/Checkout"
            element={
              <>
                <NavbarY />
                <Checkout />
              </>
            }
          />
          <Route
            path="/CustomerService"
            element={
              <>
                <NavbarY />
                <Customerservice />
              </>
            }
          />
             <Route
            path="/TPayment"
            element={
              <>
                <NavbarY />
                <TPayment />
              </>
            }
          />
          <Route
            path="/Blogs"
            element={
              <>
                <NavbarY />
                <Blogs />
              </>
            }
          />
          <Route
            path="/VMission"
            element={
              <>
                <NavbarY />
                <Vmission />
              </>
            }
          />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
