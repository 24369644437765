// Footer.js
import React from "react";
import LogoCompany from '../images/terraepawz_logo.png';
import { Link } from 'react-router-dom';
import { SocialIcon } from 'react-social-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCcVisa, faCcMastercard } from '@fortawesome/free-brands-svg-icons';


const Footer = () => {
  return (



<footer
  class="bg-black text-center mt-auto text-gray-100 lg:text-left font-roboto text-sm">
 

  

 
  <div class="mx-6 py-10 text-center md:text-left">
    <div class="grid-1 grid gap-8 md:grid-cols-2 lg:grid-cols-4">
   
      <div class="">
        <h6
          class="mb-4 flex items-center justify-center font-semibold uppercase md:justify-start">
          <span class="me-3 [&>svg]:h-4 [&>svg]:w-4">
          <img src={LogoCompany} alt="Logo" class="w-12 h-12 mr-2" />
          </span>
          Terraepawz
        </h6>
        <p>
        Your ultimate Pets Health Supplement<br />
        Kindly browse our catalog for decent products
        </p>
      </div>
     

   
      <div>
  
        <h6
          class="mb-4 inline-block justify-center font-semibold uppercase border-b-4 border-gray-600 md:justify-start">
          Customer Services
        </h6>
        <p class="mb-4">
        <Link to="/CustomerService" class="text-gray-100  text-sm  hover:text-purple-600 mr-4">FAQ</Link>
        </p>
        <p class="mb-4">
          
          <Link to="/CustomerService" class="text-gray-100  text-sm  hover:text-purple-600 mr-4">Privacy & Policy</Link>
        </p>
        <p class="mb-4">
        <Link to="/CustomerService" class="text-gray-100  text-sm  hover:text-purple-600 mr-4">Return & Refund</Link>
        </p>
        <p class="mb-4">
        <Link to="/CustomerService" class="text-gray-100  text-sm  hover:text-purple-600 mr-4">Shipping Delivery</Link>
        </p>
        <p class="mb-4">
        <Link to="/Blogs" class="text-gray-100  text-sm  hover:text-purple-600 mr-4">Blogs</Link>
        </p>
        <p class="mb-4">
        <Link to="/VMission" class="text-gray-100  text-sm  hover:text-purple-600 mr-4">Vision & Mission</Link>
        </p>
     
      </div>
 
      <div>
        <h6
          class="mb-4 inline-block justify-center font-semibold uppercase border-b-4 border-gray-600 md:justify-start">
          Contact Us
        </h6>
        <p class="mb-4 flex items-center justify-center md:justify-start">
          <span class="me-3 [&>svg]:h-5 [&>svg]:w-5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor">
              <path
                d="M11.47 3.84a.75.75 0 011.06 0l8.69 8.69a.75.75 0 101.06-1.06l-8.689-8.69a2.25 2.25 0 00-3.182 0l-8.69 8.69a.75.75 0 001.061 1.06l8.69-8.69z" />
              <path
                d="M12 5.432l8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 01-.75-.75v-4.5a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75V21a.75.75 0 01-.75.75H5.625a1.875 1.875 0 01-1.875-1.875v-6.198a2.29 2.29 0 00.091-.086L12 5.43z" />
            </svg>
          </span>
          Vetrilabz Sdn.Bhd <br />
          B-27-1, Jln Atmosphere 4 <br />
          Pusat Perniagaan Atmosphere <br />
          43300, Seri Kembangan, Selangor
        </p>
        <p class="mb-4 flex items-center justify-center md:justify-start">
          <span class="me-3 [&>svg]:h-5 [&>svg]:w-5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor">
              <path
                d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
              <path
                d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
            </svg>
          </span>
          admin@terraepawz.com
        </p>
        <p class="mb-4 flex items-center justify-center md:justify-start">
          <span class="me-3 [&>svg]:h-5 [&>svg]:w-5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor">
              <path
                fill-rule="evenodd"
                d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
                clip-rule="evenodd" />
            </svg>
          </span>
          +6010-5495008
        </p>
        
      </div>

      <div>
  
  <h6
    class="mb-4 inline-block justify-center font-semibold uppercase border-b-4 border-gray-600 md:justify-start">
    Connect With Us
  </h6>
  <p class="mb-4">
  <SocialIcon url="https://react-social-icons.com" network="x" style={{ height: 35, width: 35 }}/>
  <SocialIcon url="https://react-social-icons.com" network="facebook" style={{ height: 35, width: 35 }}/>
  <SocialIcon url="https://react-social-icons.com" network="instagram" style={{ height: 35, width: 35 }}/>
  <SocialIcon url="https://react-social-icons.com" network="tiktok" style={{ height: 35, width: 35 }}/>
  </p>
  <p>We Accept <br />
  <FontAwesomeIcon icon={faCcVisa} style={{ height: 45, width: 45, color: 'blue' }} />&nbsp;
  <FontAwesomeIcon icon={faCcMastercard} style={{ height: 45, width: 45 }} /> </p>
  

</div>




    </div>
  </div>







 
  <div class="bg-black/5 p-6 text-start text-sm ">
    <span>© 2024 Copyright </span>
    <a class="font-semibold" href="https://terraepawz.com/"
      >Terraepawz</a>. Vetrilabz Sdn.Bhd<br />
      Powered by SCV Byte
  </div>
</footer>



  );
};

export default Footer;