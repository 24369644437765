// Checkout.js
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";

const apiUrl = process.env.REACT_APP_API_URL;

const Paymentstatus = () => {

  const [billData, setBillData] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const getQueryParams = (query) => {
    return new URLSearchParams(query);
  };

  const queryParams = getQueryParams(location.search);

  const statusId = queryParams.get('status_id');
  const billcode = queryParams.get('billcode');
  //const orderId = queryParams.get('order_id');
  //const transactionId = queryParams.get('transaction_id');



  function backTo() {
    navigate('/Product');
  }

  useEffect(() => {


    const fetchbills = async () => {
      try {
        const response = await fetch(
          `${apiUrl}/api/getBill.php?bill_code=${billcode}`
        );

        const data = await response.json();

        setBillData(data);

        //calculateTotal(itemsWithQuantity);
      } catch (error) {
        console.error("Error fetching bill:", error);
      }
    };

    fetchbills();
  }, [billcode]);



  return (
    <div class=" bg-gray-100">

      <div class="flex flex-col items-center border-b bg-white py-4 sm:flex-row sm:px-10 lg:px-20 xl:px-32">
        <span class="text-2xl font-bold text-gray-800">Payment Gateway</span>
        <div class="mt-4 py-2 text-xs sm:mt-0 sm:ml-auto sm:text-base">
          <div class="relative">
            <ul class="relative flex w-full items-center justify-between space-x-2 sm:space-x-4">
              <li class="flex items-center space-x-3 text-left sm:space-x-4">
                <span class="flex h-6 w-6 items-center justify-center rounded-full bg-emerald-200 text-xs font-semibold text-emerald-700" href="#"
                ><svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" /></svg
                  ></span>
                <span class="font-semibold text-gray-900">Shop</span>
              </li>
              <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7" />
              </svg>
              <li class="flex items-center space-x-3 text-left sm:space-x-4">
                <span class="flex h-6 w-6 items-center justify-center rounded-full bg-emerald-200 text-xs font-semibold text-emerald-700" href="#"
                ><svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" /></svg
                  ></span>
                <span class="font-semibold text-gray-900">Shipping</span>
              </li>
              <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7" />
              </svg>
              <li class="flex items-center space-x-3 text-left sm:space-x-4">
                <span class="flex h-6 w-6 items-center justify-center rounded-full bg-gray-600 text-xs font-semibold text-white ring ring-gray-600 ring-offset-2" href="#">3</span>
                <span class="font-semibold text-gray-500">Payment</span>
              </li>
            </ul>
          </div>
        </div>
      </div>


        <div class="mx-auto max-w-md">
          <div class="bg-white shadow">
            <div class="px-4 py-6 sm:px-8 sm:py-10">

              <div class="mt-4">

{billData ? <div>{statusId === '1' ? <div class="text-center"><svg viewBox="0 0 24 24" class="text-green-400 w-16 h-16 mx-auto my-6">
                  <path fill="currentColor"
                    d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z">
                  </path>
                </svg><h3 class="md:text-2xl text-base text-gray-900 font-semibold text-center">Payment Successfull</h3></div> : 
                <div class="text-center"><svg viewBox="0 0 24 24" className="text-red-600 w-16 h-16 mx-auto my-6">
      <path fill="currentColor"
            d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm0 22C6.486 22 2 17.514 2 12S6.486 2 12 2s10 4.486 10 10-4.486 10-10 10zm1-15h-2v6h2V7zm0 8h-2v2h2v-2z">
      </path>
    </svg><h3 class="md:text-2xl text-base text-gray-900 font-semibold text-center">Payment Unsuccesfull</h3></div>}
              
               
    {billData.map((bill, index) => (
        <div key={index}>

                  <div class="mt-6 border-t border-b py-2">
                    <div class="flex items-center justify-between">
                      <p class="text-sm font-medium text-gray-900">Invoice No</p>
                      <p class="font-semibold text-gray-900">{bill.billpaymentInvoiceNo}</p>
                    </div>
                    <div class="flex items-center justify-between">
                      <p class="text-sm font-medium text-gray-900">Order Id</p>
                      <p class="font-semibold text-gray-900">{bill.billExternalReferenceNo}</p>
                    </div>
                    <div class="flex items-center justify-between">
                      <p class="text-sm font-medium text-gray-900">Date/Time</p>
                      <p class="font-semibold text-gray-900">{bill.billPaymentDate}</p>
                    </div>
                    <div class="flex items-center justify-between">
                      <p class="text-sm font-medium text-gray-900">Channel</p>
                      <p class="font-semibold text-gray-900">{bill.billpaymentChannel}</p>
                    </div>
                    <div class="flex items-center justify-between">
                      <p class="text-sm font-medium text-gray-900">Status</p>
                      <p class="font-semibold text-gray-900">{bill.billpaymentStatus}</p>
                    </div>
                    <div class="flex items-center justify-between">
                      <p class="text-sm font-medium text-gray-900">Amount</p>
                      <p class="font-semibold text-gray-900">{bill.billpaymentAmount}</p>
                    </div>
                    <div class="flex items-center justify-between">
                      <p class="text-sm font-medium text-gray-900">Product Purchased</p>
                      <p class="font-semibold text-gray-900">{bill.billDescription}</p>
                    </div>
                    <div class="flex items-center justify-between">
                      <p class="text-sm font-medium text-gray-900">Name</p>
                      <p class="font-semibold text-gray-900">{bill.billTo}</p>
                    </div>
                    <div class="flex items-center justify-between">
                      <p class="text-sm font-medium text-gray-900">Phone</p>
                      <p class="font-semibold text-gray-900">{bill.billPhone}</p>
                    </div>
                    <div class="flex items-center justify-between">
                      <p class="text-sm font-medium text-gray-900">Email</p>
                      <p class="font-semibold text-gray-900">{bill.billEmail}</p>
                    </div>
                  </div>

                  <div class="mt-6 my-4 text-center">
                        <button onClick={backTo} type="button" class="group inline-flex w-full items-center justify-center rounded-md bg-purple-800 px-6 py-4 text-lg font-semibold text-white transition-all duration-200 ease-in-out focus:shadow hover:bg-gray-800">
                          Back Home
                          <svg xmlns="http://www.w3.org/2000/svg" class="group-hover:ml-8 ml-4 h-6 w-6 transition-all" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M13 7l5 5m0 0l-5 5m5-5H6" />
                          </svg>
                        </button>
                      </div>
</div>         
    ))}</div>: <div class="flex items-center justify-center"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-40">
    <path stroke-linecap="round" stroke-linejoin="round" d="m6.75 7.5 3 2.25-3 2.25m4.5 0h3m-9 8.25h13.5A2.25 2.25 0 0 0 21 18V6a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 6v12a2.25 2.25 0 0 0 2.25 2.25Z" />
  </svg><br />
  <h3 class="md:text-lg text-base text-gray-500 font-semibold text-center">Error fetching bill details</h3></div>}


             

              </div>
            </div></div></div>









    </div>


  );
}

export default Paymentstatus;
