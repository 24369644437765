import React from 'react';
import ComponentBlogs from '../components/Blogs';

const Blogs = () => {
  return (

<ComponentBlogs/>

  );
};

export default Blogs;