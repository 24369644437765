import React, { useState, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import ReactPaginate from 'react-paginate';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Spinner from './Spinner';
import { FaCartPlus } from "react-icons/fa6";
import useSessionId from './useSessionId';

//import '../App.css';

const apiUrl = process.env.REACT_APP_API_URL;

const ComponentProduct = () => {

  const sessionId = useSessionId();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const s_title = queryParams.get('title');
  const [selectedCat, setSelectedCat] = useState('');


  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  const itemsPerPage = 9;


  /* ================ Section Page Render ====================*/
  /* ================ Section Page Render ====================*/

  useEffect(() => {

    setTimeout(() => {
      setLoading(false);
    }, 3000);


    const fetchProducts = async () => {

      const response = await fetch(`${apiUrl}/api/product.php`);
      const data = await response.json();

      setProducts(data.fetch_product);
      setFilteredProducts(data.fetch_product);


      const updatedCartItems = data.fetch_product.map(item => {
        const discount = ((item.price - item.price_after_disc) / item.price) * 100;
        return { ...item, discountPercentage: discount.toFixed(2), quantity: 1 };
      });
      setProducts(updatedCartItems);
      setFilteredProducts(updatedCartItems);
      //setTotalItems(data.total);

    };

    fetchProducts();

  }, []);


  /* ================ Section Search Function ====================*/
  /* ================ Section Search Function ====================*/

  useEffect(() => {

    if (s_title) {
      setSearchTerm(s_title)
      const filtered = products.filter(item =>
        item.title.toLowerCase().includes(s_title.toLowerCase())
      );
      setFilteredProducts(filtered);
    } else {
      setFilteredProducts(products);
    }



  }, [s_title, products]);


  useEffect(() => {

    const filtered = products.filter(item =>
      item.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredProducts(filtered);



  }, [searchTerm, products]);


  useEffect(() => {

    const filtered = products.filter(item =>
      item.category.toLowerCase().includes(selectedCat.toLowerCase())
    );
    setFilteredProducts(filtered);


  }, [selectedCat, products]);


  const handleCategoryChange = (e) => {
    setSelectedCat(e.target.value);
    setCurrentPage(0);

  };

  /* ================ Section Pagination Logic ====================*/
  /* ================ Section Pagination Logic ====================*/

  const handleSearchChange = (e) => {

    setSearchTerm(e.target.value);
    setCurrentPage(0);

  };

  const handlePageClick = (e) => {
    setCurrentPage(e.selected);
  };


  const handleReset = () => {
    setSearchTerm('');
    setCurrentPage(0);
  };


  const offset = currentPage * itemsPerPage;
  const currentItems = filteredProducts.slice(offset, offset + itemsPerPage);



  /* ================ Section + - Quantity ====================*/
  /* ================ Section + - Quantity ====================*/

  const increaseQuantity = (index) => {


    let actualIndex;

    if (searchTerm || selectedCat) {
      // Find the item in the filtered list
      const filteredItem = filteredProducts[index];

      // Find the actual index of this item in the main product list
      actualIndex = products.findIndex(item => item.id === filteredItem.id);
    } else {
      // Use the index directly for the non-filtered list
      actualIndex = currentPage * itemsPerPage + index;
    }


    // const actualIndex = currentPage * itemsPerPage + index;

    const newCartItems = [...products];

    newCartItems[actualIndex].quantity += 1;

    setProducts(newCartItems);

    const updatedFilteredProducts = newCartItems.filter(item =>
      item.title.toLowerCase().includes(searchTerm.toLowerCase())
      &&
      (!selectedCat || item.category === selectedCat)
    );

    setFilteredProducts(updatedFilteredProducts);
    console.log(filteredProducts);

  };


  const decreaseQuantity = (index) => {

    let actualIndex;

    if (searchTerm || selectedCat) {
      // Find the item in the filtered list
      const filteredItem = filteredProducts[index];

      // Find the actual index of this item in the main product list
      actualIndex = products.findIndex(item => item.id === filteredItem.id);
    } else {
      // Use the index directly for the non-filtered list
      actualIndex = currentPage * itemsPerPage + index;
    }

    const newCartItems = [...products];
    if (newCartItems[actualIndex].quantity > 1) {
      newCartItems[actualIndex].quantity -= 1;

      setProducts(newCartItems);

      const updatedFilteredProducts = newCartItems.filter(item =>
        item.title.toLowerCase().includes(searchTerm.toLowerCase())
        &&
        (!selectedCat || item.category === selectedCat)
      );
      setFilteredProducts(updatedFilteredProducts);


    }
  };


  /* ================ Section Add Items to Cart ====================*/
  /* ================ Section Add Items to Cart ====================*/

  const handleAddToCart = async (item) => {

    try {

      const response = await fetch(`${apiUrl}/api/addtocart.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({ title: item.title, package: item.package, id: item.id, image: item.image, qty: item.quantity, session_id: sessionId })

      });
      const result = await response.json();
      if (response.ok) {

        //console.log(result);
        toast.success(result.message);

      } else {


        throw new Error(`HTTP error! status: ${response.status}`);

      }

    }
    catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (

    <div class="bg-white">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
      <div class="flex flex-col items-center border-b bg-white py-4 sm:flex-row sm:px-10 lg:px-20 xl:px-32">
        <span class="text-2xl font-bold text-gray-800">Shop</span>&nbsp;
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="orange" class="size-10">
          <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 10.5V6a3.75 3.75 0 1 0-7.5 0v4.5m11.356-1.993 1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 0 1-1.12-1.243l1.264-12A1.125 1.125 0 0 1 5.513 7.5h12.974c.576 0 1.059.435 1.119 1.007ZM8.625 10.5a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm7.5 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
        </svg>

        <div class="mt-4 py-2 text-xs sm:mt-0 sm:ml-auto sm:text-base md:flex flex-row">
          <div class="relative">
            <ul class="relative flex w-full px-4 gap-2 items-center justify-between flex-col md:space-x-4 md:flex-row ">

              <li class="flex items-center text-left text-sm ">
                <div class="bg-purple-100 rounded-md border-l-4 border-l-purple-700 p-2 text-purple-700">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m21 7.5-9-5.25L3 7.5m18 0-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9" />
                  </svg>

                  Total Product &nbsp; <b>{products.length}</b>
                </div>
              </li>

              <li class="flex items-center space-x-3 w-1/4 text-sm sm:space-x-4 justify-center">
                <div>
                  <select value={selectedCat}
                    onChange={handleCategoryChange} class="py-1 px-1 border border-gray-300 text-gray-400 rounded-lg text-sm  ">
                    <option value="">All</option>
                    <option value="Nasal">Nasal</option>
                    <option value="Fungus">Fungus</option>
                    <option value="Worm">Worm</option>
                    <option value="Immuno Pro">ImmunoPro</option>
                    <option value="Guard Pro">GuardPro</option>
                    <option value="Immuno & Guard">GuardPro & ImmunoPro</option>
                  </select></div>
              </li>


              {searchTerm && <li class="flex items-center space-x-3 text-left text-sm sm:space-x-4">
                <div class="bg-gray-100 rounded-md border-l-4 border-l-gray-700 p-2 text-gray-700">
                  Showing <b>{filteredProducts.length}</b>
                </div>
              </li>
              }

              <li class="flex items-center space-x-3 text-left sm:space-x-4">
                <div style={{ position: 'relative', display: 'inline-block' }} >

                  <span class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-400">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                    </svg>

                  </span>
                  <input class="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" type="text" placeholder="Search product..."
                    name="search" value={searchTerm} onChange={handleSearchChange} style={{ paddingRight: '30px' }} />

                  {searchTerm && (
                    <button
                      onClick={handleReset}
                      style={{
                        position: 'absolute',
                        right: '5px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        border: 'none',
                        background: 'transparent',
                        cursor: 'pointer'
                      }}
                    >
                      ✖️
                    </button>
                  )}

                </div>
              </li>

            </ul>
          </div>
        </div>
      </div>

      <section class="flex flex-col items-center bg-gray-100">

        {filteredProducts.length > 0 ?

          <div class="mt-10 grid max-w-md grid-cols-1 gap-6 px-2 sm:max-w-lg sm:px-20 md:max-w-screen-xl md:grid-cols-2 md:px-10 lg:grid-cols-3 lg:gap-8">

            {currentItems.map((item, index) => (

              <div key={item.id}>

                <article class="mb-4 overflow-hidden rounded-xl border text-gray-700 shadow-md duration-500 ease-in-out hover:shadow-xl">
                  <div class="relative w-full pt-[100%]" >
                    {loading ? <Spinner /> : <div class="absolute top-0 left-0 w-full h-full object-cover">

                      <LazyLoadImage
                        alt="example"

                        src={`/images/product/${item.image}`} // use your image path

                        effect="blur"
                      />

                      <div class="absolute mt-3 ml-3 top-0 right-0 px-2">
                        <button class="h-6 bg-[#E74040] text-white px-3 text-sm rounded font-medium"> Sale</button>
                      </div>
                    </div>

                    }

                  </div>

                  <div class="p-4 bg-white">
                    {item.discount_status === '1' ?
                      <div class="flex flex-row">
                        <span class="py-1 px-2 mt-1 inline-flex items-center gap-x-1 text-xs font-medium bg-teal-100 text-teal-800 rounded-full dark:bg-teal-500/10 dark:text-teal-500">
                          <svg class="shrink-0 size-3" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"></path>
                            <path d="m9 12 2 2 4-4"></path>
                          </svg>
                          Available
                        </span><span class="py-1 px-2 mt-1 inline-flex items-center gap-x-1 text-xs font-medium bg-red-100 text-red-800 rounded-full dark:bg-red-500/10 dark:text-red-500">
                          <svg class="shrink-0 size-3" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <polyline points="22 17 13.5 8.5 8.5 13.5 2 7"></polyline>
                            <polyline points="16 17 22 17 22 11"></polyline>
                          </svg>
                          -{item.discountPercentage}%
                        </span> </div>
                      :
                      <div>
                        <span class="py-1 px-2 mt-1 inline-flex items-center gap-x-1 text-xs font-medium bg-teal-100 text-teal-800 rounded-full dark:bg-teal-500/10 dark:text-teal-500">
                          <svg class="shrink-0 size-3" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"></path>
                            <path d="m9 12 2 2 4-4"></path>
                          </svg>
                          Available
                        </span></div>
                    }
                    <div class="pb-3">
                      <span class="text-lg font-medium hover:text-green-900 duration-500 ease-in-out">{item.title}</span>

                    </div>

                    <ul class="box-border list-none items-center border-t border-b border-solid border-gray-200 px-0 py-2">

                      <span class="text-sm font-medium text-gray-600">{item.package}</span>
                      <br />
                      <span class="text-sm italic text-gray-400">{item.description}</span>
                      <br />
                      {item.discount_status === '1' ?
                        <div><span class="inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-lg text-sm font-bold bg-blue-100 text-purple-800  ">RM {item.price_after_disc}<span class=" line-through text-sm font-bold text-red-500">{item.price}</span></span>
                        </div> : <div><span class="inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-lg text-sm font-bold bg-blue-100 text-purple-800 ">
                          RM {item.price}</span></div>}



                    </ul>

                    <div class="flex items-end justify-end mt-2 px-4">
                      <div class="px-2">
                        <span class="text-gray-400 text-xs font-semibold">Quantity</span>
                      </div>
                      <button onClick={() => decreaseQuantity(index)}
                        class="bg-white rounded-l border text-gray-600 hover:bg-gray-100 active:bg-gray-200 disabled:opacity-50 inline-flex items-center px-2 py-1 border-r border-gray-200">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-2" fill="none" viewBox="0 0 24 24"
                          stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 12H4" />
                        </svg>
                      </button>

                      <input value={item.quantity} type="text" class=" text-gray-400 font-semibold text-sm h-6 w-6 text-center bg-transparent" />

                      <button onClick={() => increaseQuantity(index)}
                        class="bg-white rounded-r border text-gray-600 hover:bg-gray-100 active:bg-gray-200 disabled:opacity-50 inline-flex items-center px-2 py-1 border-r border-gray-200">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-2" fill="none" viewBox="0 0 24 24"
                          stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
                        </svg>
                      </button>
                    </div>


                    <div class="p-1 text-sm flex items-center justify-center gap-2 ">

                      <button onClick={() => handleAddToCart(item)}
                        class="py-2 px-4 text-sm bg-transparent border border-blue-500 text-gray-700 rounded hover:opacity-50 disabled:opacity-50 mt-4 w-full flex items-center justify-center gap-1">

                        <FaCartPlus /> Add Cart

                      </button>
                      <button
                        class="py-2 px-2 bg-pink-500 font-semibold text-white rounded hover:opacity-50 disabled:opacity-50 mt-4 w-full flex items-center justify-center">
                        Buy Now

                      </button>

                    </div>

                  </div>
                </article>
              </div>

            ))}

          </div> :

          <div class="bg-gray-100 py-10">
            <div class="mx-auto flex w-72 flex-col items-center rounded-3xl border-4 bg-white px-6 py-8 text-center">
              <div class="mb-6 text-5xl text-red-300">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-32">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
                </svg>

              </div>
              <p class="mb-2 text-xl font-medium text-gray-600">No Product Found</p>
              <p class="text-gray-500 text-sm">Your search return zero result..try again.</p>
            </div>
          </div>

        }
        <div class=" mt-4 py-4 px-4">

          <ReactPaginate
            nextLabel="Next"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={Math.ceil(filteredProducts.length / itemsPerPage)}
            previousLabel="Prev"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </div>
      </section>
    </div>

  );
};

export default ComponentProduct;