// Checkout.js
import React, { useEffect, useState } from 'react';
//import { useLocation } from "react-router-dom";
import useSessionId from './useSessionId';
import { ToastContainer, toast } from "react-toastify";
import Logotoyyib from "../images/toyyibpay.svg";
import "react-toastify/dist/ReactToastify.css";

//import CustomerDetailsForm from "./CustomerForm";

const apiUrl = process.env.REACT_APP_API_URL;

const ComponentCheckout = () => {

  const sessionId = useSessionId();
  const [cartItems, setCartItems] = useState([]);
  const [response, setResponse] = useState('');
  const [errors, setErrors] = useState({});

  const validate = () => {
    let tempErrors = {};
    if (!formData.name) tempErrors.name = 'Name is required';
    if (!formData.email) tempErrors.email = 'Email is required';
    if (!formData.phone) tempErrors.phone = 'Phone is required';
    if (!formData.address) tempErrors.address = 'Address is required';
    if (!formData.city) tempErrors.city = 'City is required';
    if (!formData.zip) tempErrors.zip = 'Zip is required';
    if (!formData.state) tempErrors.state = 'State is required';

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    zip: '',
    state: '',

  });


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };



  const shippingrate = 5;


  const totalWshiprate = cartItems.reduce((total, item) => shippingrate + total + item.price * item.qty, 0);
  const subtotal = cartItems.reduce((total, item) => total + item.price * item.qty, 0);


  useEffect(() => {

    //const sessionId = sessionStorage.getItem('sessionId');

    const fetchitems = async () => {

      try {
        const response = await fetch(`${apiUrl}/api/data_checkout.php?sessionId=${sessionId}`);

        const data = await response.json();

        setCartItems(data);



        //calculateTotal(itemsWithQuantity);
      } catch (error) {
        console.error('Error fetching items:', error);
      }
    };


    if (sessionId) {
      fetchitems();
    }

  }, [sessionId]);




  const billDescription = cartItems.map(item => `${item.title}(${item.package}) x ${item.qty} `).join(', ');

  const uniqueOrderIds = [...new Set(cartItems.map(item => item.orderId))];
  const order_id = uniqueOrderIds[0];



  const params = new URLSearchParams();

  params.append('session_id', sessionId);
  params.append('order_id', order_id);
  params.append('billDescription', billDescription);
  params.append('totalWshiprate', totalWshiprate);
  params.append('formData', JSON.stringify(formData));
  params.append('cartitems', JSON.stringify(cartItems));


  const handleCreateBill = async (e) => {
    e.preventDefault();

    if (validate()) {

      toast.info("Sending you to the payment gateway...");

      try {
        const res = await fetch('https://sales.terraepawz.com/api/createBill.php', {

          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: params

        });

        const data = await res.json();
        setResponse(data);
        const billCode = data.res_data;

        if (res.ok) {

          window.location.href = `https://dev.toyyibpay.com/${billCode}`;
          //toast.success(data.message);
        } else {

          throw new Error(`HTTP error! status: ${response.status}`);

        }

      } catch (error) {
        console.error('Error:', error);
      }

    }
  };


  return (
    <div class="bg-gray-100">

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />


      <div class="flex flex-col items-center border-b bg-white py-4 sm:flex-row sm:px-10 lg:px-20 xl:px-32">
        <span class="text-2xl font-bold text-gray-800">Shipping</span>
        <div class="mt-4 py-2 text-xs sm:mt-0 sm:ml-auto sm:text-base">
          <div class="relative">
            <ul class="relative flex w-full items-center justify-between space-x-2 sm:space-x-4">
              <li class="flex items-center space-x-3 text-left sm:space-x-4">
                <span class="flex h-6 w-6 items-center justify-center rounded-full bg-emerald-200 text-xs font-semibold text-emerald-700" href="#"
                ><svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" /></svg
                  ></span>
                <span class="font-semibold text-gray-900">Shop</span>
              </li>
              <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7" />
              </svg>
              <li class="flex items-center space-x-3 text-left sm:space-x-4">
                <span class="flex h-6 w-6 items-center justify-center rounded-full bg-gray-600 text-xs font-semibold text-white ring ring-gray-600 ring-offset-2" href="#">2</span>
                <span class="font-semibold text-gray-900">Shipping</span>
              </li>
              <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7" />
              </svg>
              <li class="flex items-center space-x-3 text-left sm:space-x-4">
                <span class="flex h-6 w-6 items-center justify-center rounded-full bg-gray-400 text-xs font-semibold text-white" href="#">3</span>
                <span class="font-semibold text-gray-500">Payment</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="bg-gray-100 grid sm:px-10 lg:grid-cols-2 lg:px-20 xl:px-32">
        <div class="px-4 pt-8">
          <p class="text-xl font-medium">Order Summary</p>
          <p class="text-gray-400">Check and verify your items.</p>
          <div class="mt-8 space-y-3 rounded-lg border bg-white px-2 py-4 sm:px-6">

            {cartItems.map(item => (
              <div key={item.id}>
                <div class="flex flex-col rounded-lg bg-white sm:flex-row">
                  <img class="m-2 h-24 w-28 rounded-md border object-cover object-center" src={`/images/product/${item.image}`} alt="" />
                  <div class="flex w-full flex-col px-4 py-4">
                    <span class="font-semibold">{item.title}</span>
                    <span class="float-right text-gray-400">{item.package}</span>
                    <span class="float-right text-gray-400">x {item.qty}</span>
                    <p class="text-lg font-bold"><span class="text-xs font-normal text-gray-400">RM</span> {item.sub_total}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <p class="mt-8 text-lg font-medium">Payment Channel</p>
          <p class="text-gray-400">At the moment, we have below payment channel only.</p>
          <div class="relative mt-4 bg-white">
            <input class="peer hidden" id="radio_1" type="radio" name="radio" checked />
            <span class="peer-checked:border-gray-700 absolute right-4 top-1/2 box-content block h-3 w-3 -translate-y-1/2 rounded-full border-8 border-gray-300 bg-white"></span>
            <label class=" flex cursor-pointer select-none rounded-lg border border-gray-300 p-4" for="radio_1">
              <img class="w-20 object-contain" src={Logotoyyib} alt="" />
              <div class="ml-5">
                <span class="mt-2 font-semibold">Toyyib Pay</span>
                <p class="text-slate-500 text-sm leading-6">FPX Online Payment Gateway</p>
              </div>
            </label>
          </div>

        </div>
        <div class="mt-10 bg-gray-50 px-4 pt-8 lg:mt-0">
          <p class="text-xl font-medium">Shipping Details</p>
          <p class="text-gray-400">Complete your order by providing your shipping details.</p>

          <label for="name" class="mt-4 mb-2 block text-sm font-medium">Name</label>
          <div class="relative">
            <input type="text" name="name" value={formData.name} onChange={handleChange} class="w-full rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500" placeholder="Mohd Iskandar" require />
            {errors.name && <p className="text-red-500 text-xs font-medium italic">{errors.name}</p>}
          </div>

          <label for="phone" class="mt-4 mb-2 block text-sm font-medium">Phone</label>
          <div class="relative">
            <input type="text" name="phone" value={formData.phone} onChange={handleChange} class="w-full rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500" placeholder="+601123456789" required />
            {errors.phone && <p className="text-red-500 text-xs font-medium italic">{errors.phone}</p>}
          </div>

          <label for="email" class="mt-4 mb-2 block text-sm font-medium">Email</label>
          <div class="relative">
            <input type="text" name="email" value={formData.email} onChange={handleChange} class="w-full rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500" placeholder="email@gmail.com" required />
            {errors.email && <p className="text-red-500 text-xs font-medium italic">{errors.email}</p>}
          </div>


          <label for="billing-address" class="mt-4 mb-2 block text-sm font-medium">Shipping Address</label>

          <div class="relative">
            <input type="text" name="address" value={formData.address} onChange={handleChange} class="w-full rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500" placeholder="7, Jalan Teknokrat" required />

          </div>
          <div class="flex flex-col gap-2 sm:flex-row">
            <input type="text" name="city" value={formData.city} onChange={handleChange} class="flex-shrink-0 rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none sm:w-1/6 focus:z-10 focus:border-blue-500 focus:ring-blue-500" placeholder="Cyberjaya" required />

            <input type="text" name="zip" value={formData.zip} onChange={handleChange} class="flex-shrink-0 rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none sm:w-1/6 focus:z-10 focus:border-blue-500 focus:ring-blue-500" placeholder="41200" required />

            <select type="text" name="state" value={formData.state} onChange={handleChange} class="flex-shrink-0 rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500" required>
              <option value="">Choose State</option>
              <option value="Johor">Johor</option>
              <option value="Melaka">Melaka</option>
              <option value="Pahang">Pahang</option>
              <option value="Negeri Sembilan">Negeri Sembilan</option>
              <option value="Selangor">Selangor</option>
              <option value="Perak">Perak</option>
              <option value="Terengganu">Terengganu</option>
              <option value="Kelantan">Kelantan</option>
              <option value="Pulau Pinang">Pulau Pinang</option>
              <option value="Kedah">Kedah</option>
              <option value="Perlis">Perlis</option>
              <option value="Sabah">Sabah</option>
              <option value="Sarawak">Sarawak</option>
              <option value="WP Kuala Lumpur">WP Kuala Lumpur</option>
              <option value="WP Putrajaya">WP Putrajaya</option>
              <option value="WP Labuan">WP Labuan</option>
            </select>

          </div>
          <div class="mt-2">
            {errors.address && <p className="text-red-500 text-xs font-medium italic">{errors.address}</p>}
            {errors.city && <p className="text-red-500 text-xs font-medium italic">{errors.city}</p>}

            {errors.state && <p className="text-red-500 text-xs font-medium italic">{errors.state}</p>}

            {errors.zip && <p className="text-red-500 text-xs font-medium italic">{errors.zip}</p>}

          </div>
          <div class="mt-6 border-t border-b py-2">
            <div class="flex items-center justify-between">
              <p class="text-sm font-medium text-gray-900">Subtotal</p>
              <p class="font-semibold text-gray-900">{subtotal.toFixed(2)}</p>
            </div>
            <div class="flex items-center justify-between">
              <p class="text-sm font-medium text-gray-900">Postage</p>
              <p class="font-semibold text-gray-900">{shippingrate.toFixed(2)}</p>
            </div>
          </div>
          <div class="mt-6 flex items-center justify-between">
            <p class="text-sm font-medium text-gray-900">Grand Total</p>
            <p class="text-2xl font-semibold text-gray-900"><span class="text-xs font-normal text-gray-400">RM</span> {totalWshiprate.toFixed(2)}</p>
          </div>

          <div class="mt-6 my-4 text-center">
            <button onClick={handleCreateBill} type="button" class="group inline-flex w-full items-center justify-center rounded-md bg-purple-800 px-6 py-4 text-lg font-semibold text-white transition-all duration-200 ease-in-out focus:shadow hover:bg-gray-800">
              Place Your Order
              <svg xmlns="http://www.w3.org/2000/svg" class="group-hover:ml-8 ml-4 h-6 w-6 transition-all" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M13 7l5 5m0 0l-5 5m5-5H6" />
              </svg>
            </button>
          </div>
        </div>



      </div>

    </div>


  );
};

export default ComponentCheckout;
