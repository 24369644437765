import React from 'react';
import ComponentProduct from '../components/Product';

const Product = () => {
  return (

<ComponentProduct/>

  );
};

export default Product;