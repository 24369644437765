// Spinner.js
import React from 'react';
import { ThreeDots } from 'react-loader-spinner';

const Spinner = () => (
  <div className="flex items-center justify-center">
    <ThreeDots
    height="30"
    width="30"
    color="gray"
    ariaLabel="loading"

  />
  </div>
);

export default Spinner;
