import React from 'react';
import Paymentstatus from '../components/PaymentTp';

const TPayment = () => {
  return (

<Paymentstatus />

  );
};

export default TPayment;