import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "../App.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import useSessionId from "./useSessionId";

//import { v4 as uuidv4 } from 'uuid';

const apiUrl = process.env.REACT_APP_API_URL;

const ComponentCart = () => {
  const navigate = useNavigate();
  const sessionId = useSessionId();
  const [cartItems, setCartItems] = useState([]);
  //const [error, setError] = useState(null);

  const tax = 0.00;


  const [orderId, setOrderId] = useState('');



  /*function backToShop() {
    navigate('/Product');
  }*/




  useEffect(() => {
    //const sessionId = sessionStorage.getItem('sessionId');

    const fetchitems = async () => {
      try {
        const response = await fetch(
          `${apiUrl}/api/data.php?sessionId=${sessionId}`
        );
        console.log(response);
        const data = await response.json();
        const itemsWithQuantity = data.map((item) => ({
          ...item,
          quantity: item.qty,
        }));
        setCartItems(itemsWithQuantity);
        //calculateTotal(itemsWithQuantity);
      } catch (error) {
        console.error("Error fetching items:", error);
      }
    };

    if (sessionId) {
      fetchitems();
    }
  }, [sessionId]);

  const handleSelect = (id) => {
    //console.log(id);
    setCartItems(
      cartItems.map((item) =>
        item.id === id ? { ...item, selected: !item.selected } : item
      )
    );
  };

  const itemSelected = cartItems.filter((item) => item.selected);

  const increaseQuantity = (index) => {
    const newCartItems = [...cartItems];
    newCartItems[index].quantity = Number(newCartItems[index].quantity) + 1;
    newCartItems[index].subtotal =
      newCartItems[index].price * newCartItems[index].quantity;
    setCartItems(newCartItems);
    //calculateTotal(newCartItems);
  };

  const decreaseQuantity = (index) => {
    const newCartItems = [...cartItems];
    if (newCartItems[index].quantity > 1) {
      newCartItems[index].quantity = Number(newCartItems[index].quantity) - 1;
      newCartItems[index].subtotal =
        newCartItems[index].price * newCartItems[index].quantity;
      setCartItems(newCartItems);
      //calculateTotal(newCartItems);
    }
  };

  //const totalItems = cartItems.reduce((total, item) => total + item.quantity, 0);

  const totalAmount = cartItems
    .filter((item) => item.selected)
    .reduce((total, item) => total + item.price * item.quantity, 0);

  const totalAmountTax = cartItems
    .filter((item) => item.selected)
    .reduce((total, item) => tax + total + item.price * item.quantity, 0);

  const removeItem = async (itemId) => {
    try {
      const response = await fetch(`${apiUrl}/api/delete.php?id=${itemId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: itemId }),
      });
      const result = await response.json();
      if (response.ok) {
        setCartItems(cartItems.filter((item) => item.id !== itemId));

        toast.success(result.message);
      } else {
        toast.error("Failed to delete item from database");
      }
    } catch (error) {
      toast.error("Error: " + error.message);
    }
  };



  const isAnyItemChecked = cartItems.some((item) => item.selected);



  const dataToSend = {
    items: itemSelected,
    orderId: orderId
  };

  const generateRandomString = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
    return result;
  };






  const checkOrderIdExists = async (id) => {
    const response = await fetch(`${apiUrl}/api/checkOrderId.php?id=${id}`);
    const data = await response.json();
    return data.exists;
  };


  const generateUniqueOrderId = async () => {
    let newOrderId;
    let exists = true;

    while (exists) {
      newOrderId = generateRandomString(8);
      exists = await checkOrderIdExists(newOrderId);
    }

    setOrderId(newOrderId);

  };


  useEffect(() => {
    generateUniqueOrderId();
  },);


  const handleCheckout = async () => {


    toast.info("Processing your request...");



    try {

      //const itemSelected = cartItems.filter((item) => item.selected);

      //console.log(itemSelected);

      const response = await fetch(`${apiUrl}/api/checkout.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataToSend),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      //const result = await response.json();
      //toast.success(result.message);

      setTimeout(() => {
        navigate("/Checkout");
      }, 4000); // Adjust the delay time as needed

      /*if (response.ok) {

          await new Promise(resolve => setTimeout(resolve, 3000));
            toast.success(result.message);

            setTimeout(() => {
                navigate('/Checkout');
              }, 4000); // Adjust the delay time as needed

        } else {
            toast.error('Error! Something went wrong.');
        }*/
    } catch (error) {
      console.error(error.message);
    }
  };

  return (

    <div class=" bg-gray-100">
      
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

      <div class="flex flex-col items-center border-b bg-white py-4 sm:flex-row sm:px-10 lg:px-20 xl:px-32">
        <span class="text-2xl font-bold text-gray-800">Shopping Cart</span>
        <div class="mt-4 py-2 text-xs sm:mt-0 sm:ml-auto sm:text-base">
          <div class="relative">
            <ul class="relative flex w-full items-center justify-between space-x-2 sm:space-x-4">
              <li class="flex items-center space-x-3 text-left sm:space-x-4">
              <span class="flex h-6 w-6 items-center justify-center rounded-full bg-gray-600 text-xs font-semibold text-white ring ring-gray-600 ring-offset-2" href="#">1</span>
                <span class="font-semibold text-gray-900">Shop</span>
              </li>
              <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7" />
              </svg>
              <li class="flex items-center space-x-3 text-left sm:space-x-4">
              <span class="flex h-6 w-6 items-center justify-center rounded-full bg-gray-400 text-xs font-semibold text-white" href="#">2</span>
                <span class="font-semibold text-gray-900">Shipping</span>
              </li>
              <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7" />
              </svg>
              <li class="flex items-center space-x-3 text-left sm:space-x-4">
                <span class="flex h-6 w-6 items-center justify-center rounded-full bg-gray-400 text-xs font-semibold text-white" href="#">3</span>
                <span class="font-semibold text-gray-500">Payment</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

        <div class="mx-auto mt-1 max-w-2xl md:mt-4">
          <div class="bg-white shadow">
            <div class="px-4 py-6 sm:px-8 sm:py-10">

              {cartItems.length === 0 ? (
                <p class="px-6 py-3 text-center text-xl font-medium text-gray-500 ">
                  <ShoppingCartIcon color="gray" />
                  <br />
                  Your Cart is empty
                 </p>

              ) : (
                <div>
                    <p class="text-xl font-medium">Cart Summary</p>
                    <p class="text-gray-400">Tick item you want to buy</p>
                  {cartItems.map((item, index) => (
                    <div key={item.id}>
                      <div class="flow-root">
                        <ul class="-my-8">
                          
                          <li class="flex flex-col space-y-3 py-12 text-left sm:flex-row sm:space-x-5 sm:space-y-0">
                            <div class="shrink-0">
                              <input
                                type="checkbox"
                                checked={item.selected}
                                onChange={() => handleSelect(item.id)}
                                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                              />
                            </div>
                            <div class="shrink-0">
                              <img class="h-24 w-24 max-w-full rounded-lg object-cover" src={`/images/product/${item.image}`} alt="" />
                            </div>

                            <div class="relative flex flex-1 flex-col justify-between">
                              <div class="sm:col-gap-5 sm:grid sm:grid-cols-2">
                                <div class="pr-8 sm:pr-5">
                                  <p class="text-base font-semibold text-gray-900">{item.title}</p>
                                  <p class="mx-0 mt-1 mb-0 text-sm text-gray-400">{item.package}</p>
                                  <p class="mx-0 mt-1 mb-0 text-sm text-gray-400">{item.price}/Unit</p>

                                </div>

                                <div class="mt-4 flex items-end justify-between sm:mt-0 sm:items-start sm:justify-end">
                                  <p class="shrink-0 w-20 text-base font-semibold text-gray-900 sm:order-2 sm:ml-8 sm:text-right">{(item.quantity * item.price).toFixed(2)}</p>

                                  <div class="sm:order-1">
                                    <div class="mx-auto flex h-8 items-stretch text-gray-600">
                                      <button
                                        onClick={() => decreaseQuantity(index)}
                                        class="group px-2 py-[10px] border border-gray-400 flex items-center justify-center shadow-sm shadow-transparent transition-all duration-500 hover:bg-gray-50 hover:border-gray-300 hover:shadow-gray-300 focus-within:outline-gray-300"
                                      >
                                        <svg
                                          class="stroke-gray-700 transition-all duration-500 "
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="8"
                                          height="8"
                                          viewBox="0 0 22 22"
                                          fill="none"
                                        >
                                          <path
                                            d="M16.5 11H5.5"
                                            stroke=""
                                            stroke-width="1.6"
                                            stroke-linecap="round"
                                          />
                                          <path
                                            d="M16.5 11H5.5"
                                            stroke=""
                                            stroke-opacity="0.2"
                                            stroke-width="1.6"
                                            stroke-linecap="round"
                                          />
                                          <path
                                            d="M16.5 11H5.5"
                                            stroke=""
                                            stroke-opacity="0.2"
                                            stroke-width="1.6"
                                            stroke-linecap="round"
                                          />
                                        </svg>
                                      </button>
                                      <input
                                        type="text"
                                        class="text-gray-400 font-semibold text-sm w-full max-w-[10px] min-w-[40px] placeholder:text-gray-900 py-[4px]  text-center bg-transparent"
                                        value={item.quantity}
                                      />
                                      <button
                                        onClick={() => increaseQuantity(index)}
                                        class="group px-2 py-[10px] border border-gray-400 flex items-center justify-center shadow-sm shadow-transparent transition-all duration-500 hover:bg-gray-50 hover:border-gray-300 hover:shadow-gray-300 focus-within:outline-gray-300"
                                      >
                                        <svg
                                          class="stroke-gray-700 transition-all duration-500"
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="8"
                                          height="8"
                                          viewBox="0 0 22 22"
                                          fill="none"
                                        >
                                          <path
                                            d="M11 5.5V16.5M16.5 11H5.5"
                                            stroke=""
                                            stroke-width="1.6"
                                            stroke-linecap="round"
                                          />
                                          <path
                                            d="M11 5.5V16.5M16.5 11H5.5"
                                            stroke=""
                                            stroke-opacity="0.2"
                                            stroke-width="1.6"
                                            stroke-linecap="round"
                                          />
                                          <path
                                            d="M11 5.5V16.5M16.5 11H5.5"
                                            stroke=""
                                            stroke-opacity="0.2"
                                            stroke-width="1.6"
                                            stroke-linecap="round"
                                          />
                                        </svg>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="absolute top-0 right-0 flex sm:bottom-0 sm:top-auto">
                                <button type="button" onClick={() => removeItem(item.id)} class="flex rounded p-2 text-center text-gray-500 transition-all duration-200 ease-in-out focus:shadow hover:text-gray-900">
                                  <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" class=""></path>
                                  </svg>
                                </button>
                              </div>
                            </div>
                          </li>

                        </ul>
                      </div>
                      </div>
                    ))}
                </div>
              )}
                 
                      <div class="mt-6 border-t py-2">

                        <div class="flex items-center justify-between">
                          <p class="text-sm text-gray-400">Summary</p>
                          <p class="text-sm text-gray-400">
                            {itemSelected.map((item, index) => (
                              <div key={index} className="item">
                                {item.title}({item.package})<br />
                                {item.quantity} x {item.price} ={" "}
                                {(item.quantity * item.price).toFixed(2)}

                              </div>
                            ))}
                          </p>
                        </div>


                      </div>


                      <div class="mt-6 border-t border-b py-2">
                        <div class="flex items-center justify-between">
                          <p class="text-sm text-gray-400">Total Order</p>
                          <p class="text-lg font-semibold text-gray-900">{itemSelected.length}</p>
                        </div>
                        <div class="flex items-center justify-between">
                          <p class="text-sm text-gray-400">Subtotal</p>
                          <p class="text-lg font-semibold text-gray-900">{totalAmount.toFixed(2)}</p>
                        </div>
                        <div class="flex items-center justify-between">
                          <p class="text-sm text-gray-400">Shipping</p>
                          <p class="text-sm text-gray-400">Flat Rate</p>
                        </div>
                      </div>
                      <div class="mt-6 flex items-center justify-between">
                        <p class="text-sm font-medium text-gray-900">Grand Total</p>
                        <p class="text-2xl font-semibold text-gray-900"><span class="text-xs font-normal text-gray-400">RM</span> {totalAmountTax.toFixed(2)}</p>
                      </div>

                      <div class="mt-6 text-center">
                        <button onClick={handleCheckout} disabled={!isAnyItemChecked} type="button" class="group inline-flex w-full items-center justify-center rounded-md bg-purple-800 px-6 py-4 text-lg font-semibold text-white transition-all duration-200 ease-in-out focus:shadow hover:bg-gray-800">
                          Checkout
                          <svg xmlns="http://www.w3.org/2000/svg" class="group-hover:ml-8 ml-4 h-6 w-6 transition-all" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M13 7l5 5m0 0l-5 5m5-5H6" />
                          </svg>
                        </button>
                      </div>
                  
          
           
     
          
            </div>
          </div>
          </div>
      
     
    </div>
  );
};

export default ComponentCart;
